import React from 'react'
import { Link } from 'gatsby'
import { Highlight } from 'react-instantsearch-dom'

class SearchHit extends React.Component {
  render () {
    const locale = this.props.locale || 'en-US'

    // @TODO we need to send the chapter to sections lookup here

    const hit = this.props.hit
    const hitType = hit.contentType
    switch (hitType) {
      case 'chapter':
        return (
          <Link className='cursor-pointer px-2 py-10 pt-16' to={`/${locale}/chapter/${hit.slug}`}>
            <div className='mb-4'>
              <span className='font-bold text-3xl'><Highlight hit={hit} attribute='title' /></span>
            </div>
            <div className='mb-2'>
              <span className='leading-none'><Highlight hit={hit} attribute='intro.intro' className='text-xs' /></span>
            </div>
          </Link>
        )

      default:
        return (<p className='bg-red-100 border-red-800 border-solid border-2 rounded p-2 text-red-800'><strong>Error</strong>: Unknown search result type.</p>)
    }
  }
}

export default SearchHit
