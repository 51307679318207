import React from 'react'
import algoliasearch from 'algoliasearch/lite'
import {
  InstantSearch,
  SearchBox,
  InfiniteHits,
  // Pagination,
  Configure,
  connectStateResults,
  Index
} from 'react-instantsearch-dom'
import { FormattedMessage } from 'react-intl'

import SearchHit from '../components/search-hit'
import Layout from '../components/layout'
import SEO from '../components/seo'

class SearchPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      searchKey: ''
    }

    this.searchClient = algoliasearch(
      process.env.GATSBY_ALGOLIA_APPLICATION_ID,
      process.env.GATSBY_ALGOLIA_KEY
    )
  }

  componentDidMount () {
    if (this.props.location.search) {
      const keywords = this.props.location.search.replace('?', '').split('&')
      const keyword = keywords.find(k => k.includes('searchkey'))

      if (keyword) {
        this.setState({ searchKey: decodeURIComponent(keyword.split('=')[1]) })
      }
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      const keywords = this.props.location.search.replace('?', '').split('&')
      const keyword = keywords.find(k => k.includes('searchkey'))

      this.setState({ searchKey: keyword.split('=')[1] })
      window.location.reload()
    }
  }

  render () {
    const searchIndex = this.props.pageContext.locale === 'es-MX' ? 'chaptersSpanish' : 'chaptersEnglish'
    console.log('searchIndex: ', searchIndex)

    return (
      <Layout locale={this.props.pageContext.locale} location={this.props.location}>
        <SEO title='Search' />
        {/* // @TODO add in hero image from first node */}
        <div className='main-content container px-4 mx-auto top- mb-12'>
          <div className='mt-4'>
            <InstantSearch
              indexName={searchIndex}
              searchClient={this.searchClient}
            >
              <div className='bg-turqouise p-4 flex items-center h-full '>
                <SearchBox
                  defaultRefinement={this.state.searchKey}
                  autoFocus
                  translations={{
                    submitTitle: 'Submit your search.',
                    resetTitle: 'Clear your search.',
                    placeholder: 'What are you looking for?'
                  }}
                  onChange={event => {
                    this.setState({ searchKey: event.target.value })
                  }}
                  className='w-full'
                />
              </div>
              <div className='mt-4 font-bold text-3xl'><FormattedMessage id='search-result-for-title' /> "{this.state.searchKey}".</div>
              {/* // @TODO no results */}
              <AllResults searchIndex={searchIndex}>
                <div>
                  <Index indexName={searchIndex}>
                    <IndexResults>
                      <div>
                        <InfiniteHits
                          hitComponent={data => <SearchHit hit={data.hit} locale={this.props.pageContext.locale} />}
                          translations={{
                            loadMore: 'Load more results'
                          }}
                          transformItems={(items) => {
                            return items
                          }}
                        />
                      </div>
                    </IndexResults>
                  </Index>
                </div>
              </AllResults>
              <Configure hitsPerPage={5} />
              {/* <Pagination /> */}
            </InstantSearch>
          </div>
        </div>
      </Layout>
    )
  }
}

const IndexResults = connectStateResults(
  ({ searchState, searchResults, children }) =>
    searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      <div className='font-semibold text-2xl mt-10 pl-4'>
        <FormattedMessage id='search.noresult' />
      </div>
    )
)

const AllResults = connectStateResults(({ allSearchResults, children, searchIndex }) => {
  return !allSearchResults ? (
    <div>
      <div>No Search Results</div>
    </div>
  ) : (
    children
  )
})

export default SearchPage
